
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "../../../store/enums/StoreEnums";
import { getIllustrationsPath } from "../../../core/helpers/assets";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { ref } from "vue";
import { reactive } from "vue";


const formInline = reactive({
  walletID: "",
  transactionID: "",
  createdDate: ""
});

const onSubmit = () => {
  console.log("submit!");
};

const tableData = ref([
  {

    adjustID:"1110",
    transactionId: "T000031312312",

    walletID: "W000002437",
    availableBalance: "3222",
    unavailableBalance: "111",
    amount: "3.01",
    currency: "NZD",
    createdDate: "2023-11-11 11:11:11"
  },
  {
    adjustID:"1110",
    transactionId: "T000031312312",

    walletID: "W000002437",
    availableBalance: "3222",
    unavailableBalance: "111",
    amount: "3.01",
    currency: "NZD",
    createdDate: "2023-11-11 11:11:11"
  },
  {
    adjustID:"1110",
    transactionId: "T000031312312",

    walletID: "W000002437",
    availableBalance: "3222",
    unavailableBalance: "111",
    amount: "3.01",
    currency: "NZD",
    createdDate: "2023-11-11 11:11:11"
  },
  {
    adjustID:"1110",
    transactionId: "T000031312312",

    walletID: "W000002437",
    availableBalance: "3222",
    unavailableBalance: "111",
    amount: "3.01",
    currency: "NZD",
    createdDate: "2023-11-11 11:11:11"
  }
]);


export default defineComponent({
  name: "merchantSettlement",
  components: {},
  setup() {
    const store = useStore();

    onMounted(() => {


      setCurrentPageTitle("Merchant Settlement");
    });


    return {
      getIllustrationsPath,
      formInline,
      onSubmit,
      tableData
    };
  },
});
